import dashboardServices from '../../../services/provider/dashboard'
import { RECALL_CLASSIFICATIONS, THRESHOLD_COLOR_STATUS ,RECALL_STATUS,OUTPUT_DATE_FORMAT,PROVIDER_EVENTLISTING_STATUS, COMMUNICATION_TYPE, RECALL_TYPE, ANTICIPATED_DURATION, PROVIDER_DASHBOARD_STATUS, PROVIDER_DASHBOARD_TYPE, PROVIDER_DASHBOARD_TYPE1, RECALL_TYPE_DASHBOARD_CARD} from '../../../misc/commons'
import _ from 'lodash'
import { search } from 'core-js/fn/symbol'
export default {
    data() {
        return {
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            status: 'OPEN',
            perPage: 50,
            perPageEmit: 50,
            currentPage: 1,
            per_page: [10, 20, 30, 40, 50],
            recall: COMMUNICATION_TYPE.RECALL,
            productdisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            anticipated_duration: ANTICIPATED_DURATION,
            recall_type: RECALL_TYPE_DASHBOARD_CARD,
            totalItems: null,
            items: [],
            Originalitems: [],
            title: '',
            provider_status: PROVIDER_EVENTLISTING_STATUS,
            recall_classifications: RECALL_CLASSIFICATIONS,
            threshold_color_status: THRESHOLD_COLOR_STATUS,
            provider_dashboard_status: PROVIDER_DASHBOARD_STATUS,
            provider_dashboard_type: PROVIDER_DASHBOARD_TYPE,
            provider_dashboard_supplier: [],
            minHeight: {
                meta: 0,
                title: 0,
                cate: 0
            },
            search: {
                status: 'Open',
                eventCategory: 'ALL',
                supplier_names: 'Select',
            },
            ChildRecalltotal: 0,
            ChildRecall: [],
            recall_status: _.cloneDeep(RECALL_STATUS),
            header: [
                {
                    label: 'Complete',
                    name: 'status_provider',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-2ercent',
                    row_classes: 'customalign-2percent wordbreakall',
                },
                {
                    label: 'Supplier',
                    name: 'supplier_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Event ID',
                    name: 'communicationUID',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Name',
                    name: 'communicationName',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Categories',
                    name: 'categories',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                    sort: true,
                },
                {
                    label: 'Site',
                    name: 'recall_site',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },

                {
                    label: 'Response Documents',
                    name: 'response_doc',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },

                {
                    label: 'Source',
                    name: 'is_provider_created',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },

                {
                    label: 'Days Since Notified',
                    name: 'days_since_notified',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Event Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                },
            ],
        }
    },
    created() {
        if (this.$store.state.lastedRouteNotAuth.fullPath != undefined) {
            return this.$router.push({ path: this.$store.state.lastedRouteNotAuth.fullPath })
        }
        if (this._checkPermissions(['Provider_ViewDashboard'], ['Provider_ResponderRole'])) {
            if((this._checkPermissions(['Provider_ManageRecalls'])) && (this._checkPermissions(['Provider_ManageDisruption'])))
            {
                this.search.eventCategory = 'ALL'
            }
            else if(this._checkPermissions(['Provider_ManageDisruption'])) {
                this.search.eventCategory = this.productdisruption
            }
            else if(this._checkPermissions(['Provider_ManageRecalls'])) {
                this.search.eventCategory = this.recall
            }
            this.findRecallsCard()
        }
    },
    watch: {
        perPageEmit(val) {
            this.$emit('update:searchQuery', {
                pageNumber: this.currentPage,
                pageSize: val,
            })
            this.requestFetchData()
        },
        currentPage(val) {
            this.$emit('update:searchQuery', {
                pageNumber: val,
                pageSize: this.perPageEmit,
            })
            this.requestFetchData()
        },
        'search.supplier_names': function(newVal, oldVal) {
            if (newVal && newVal.includes('Select') && !oldVal.includes('Select')) {
                this.search.supplier_names = 'Select'
            } else if (newVal && oldVal.includes('Select') && newVal.length > 0) {
                const index = newVal.indexOf('Select')
                if (index !== -1) {
                    newVal.splice(index, 1)
                }
                this.search.supplier_names = newVal.join(';')
            } else if (newVal && newVal.length === 0) {
                this.search.supplier_names = 'Select'
            } else if (Array.isArray(newVal)) {
                this.search.supplier_names = newVal.join(';')
            }
        }
    },
    methods: {
        findRecallsCard() {
            const supplierNames = this.search.supplier_names === 'Select' ? null : this.search.supplier_names

            dashboardServices.findRecallsCard({
                pageNumber: this.currentPage,
                pageSize: this.perPage,
                sortField: null,
                sortDirection: null,
                status: this.search.status ? this.search.status : 'ALL',
                eventCategory: this.search.eventCategory ? this.search.eventCategory : 'ALL',
                supplier_names: supplierNames,
            }).then(resp => {
                if (!resp.error) {
                    this.getSupplierFilter()
                    const recallsarray = []

                    if (this.$store.state.roleName !== undefined && (this.$store.state.roleName === 'Administrator' || this.$store.state.roleName === 'NotiSphere Admin')) {
                        this.Originalitems = resp.data.d.c.filter(s => s.recallSiteId > 0)
                        for (var i = 0; i < resp.data.d.c.length; i++) {
                            var total_actioncnt = null
                            var total_response_actioncnt = null
                            var total_recallacknowledgedcnt = 0
                            var total_recallcompletedcnt = 0
                            resp.data.d.c[i].recallSiteCount = resp.data.d.c.filter(s => s.communicationId == resp.data.d.c[i].communicationId && s.recallSiteId > 0).length
                            var TotalRecallCount = resp.data.d.c.filter(s => s.communicationId == resp.data.d.c[i].communicationId)
                            TotalRecallCount.forEach(element => {
                                total_actioncnt += parseInt(element.total_action)
                                total_response_actioncnt += parseInt(element.total_response_action)
                                total_recallcompletedcnt = (element.completed_datetime ? (total_recallcompletedcnt + 1) : (total_recallcompletedcnt + 0))
                                total_recallacknowledgedcnt = (element.acknowledge_datetime ? (total_recallacknowledgedcnt + 1) : (total_recallacknowledgedcnt + 0))
                            })

                            resp.data.d.c[i].total_action = total_actioncnt
                            resp.data.d.c[i].total_response_action = total_response_actioncnt
                            if (total_recallacknowledgedcnt == TotalRecallCount.length) {
                                resp.data.d.c[i].isparentrecallacknowledged = true
                            }
                            if (total_recallcompletedcnt == TotalRecallCount.length) {
                                resp.data.d.c[i].isparentrecallcompleted = true
                            }
                            if (TotalRecallCount.length > 0) {
                                resp.data.d.c[i].dashboard_progress = (total_recallcompletedcnt  / TotalRecallCount.length) * 100
                            }
                            var checkexist = recallsarray.filter(s => s.communicationId == resp.data.d.c[i].communicationId)
                            if (checkexist.length == 0) {
                                recallsarray.push(resp.data.d.c[i])
                            }
                        }
                        this.items = recallsarray
                        this.items.forEach(el => {
                            el.supplier_action_progress = el.completed_datetime != null ? 100 : el.totalSupplierAction > 0 ? Math.round((el.completedSupplierAction / el.totalSupplierAction) * 100) : 0
                            el.delegate_action_progress = el.delegateTotalAction > 0 ? Math.round((el.delegateNumberOfAction / el.delegateTotalAction) * 100) : 0
                            el.progress = Math.round(el.dashboard_progress > 0 ? el.dashboard_progress : 0)
                            if(el.progress == 100)
                            {
                                el.completed_color = 'GREEN'
                            }
                        })
                        this.totalItems = resp.data.d.t
                        this.$nextTick(() => {
                            this.calcHeight()
                        })
                    }
                    else{
                        this.items = resp.data.d.c
                        this.items.forEach(el => {
                            el.supplier_action_progress =  el.completed_datetime != null ? 100: el.totalSupplierAction > 0 ? Math.round((el.completedSupplierAction / el.totalSupplierAction) * 100) : 0
                            el.delegate_action_progress = el.delegateTotalAction > 0 ? Math.round((el.delegateNumberOfAction / el.delegateTotalAction) * 100) : 0
                            el.isparentrecallcompleted =el.status_provider=='COMPLETED'? true : false
                            el.progress = Math.round(el.completed_datetime != null ? 100 : 0)
                            if(el.completed_datetime != null)
                            {
                                el.completed_color = 'GREEN'
                            }
                        })
                        this.totalItems = resp.data.d.t
                        this.$nextTick(() => {
                            this.calcHeight()
                        })
                    }
                }
            })
        },
        getSupplierFilter() {
            dashboardServices.findSupplierFilter(this.search.eventCategory ? this.search.eventCategory : 'ALL').then(resp => {
                var data = resp.data.d
                const suppliers = data.supplier_name.map(x => ({ text: x, value: x }))
                this.provider_dashboard_supplier = [{ text: 'All', value: 'Select' }, ...suppliers]
            })
        },
        requestFetchData() {
            //error on server mode ( when not in page 1 and make filter, it will emit twice the request) => set timeout
            if (this.timer2) {
                clearTimeout(this.timer2)
                this.timer2 = null
            }
            this.timer2 = setTimeout(() => {
                this.findRecallsCard()
            }, 5)
        },
        statusChange(status) {
            this.status = status
            this.findRecallsCard()
        },

        selectPerPage(perPage) {
            this.perPage = perPage
            this.perPageEmit = perPage
        },
        isActiveStatus(status) {
            return this.status == status ? 'btn btn-primary' : 'btn btn-secondary'
        },
        viewChildRecall(item)
        {
            if(item.communicationType == this.recall)
            {
                this.$router.push({
                    name: 'prov_RecallView',
                    params: { id: item.communicationId ,providerCommunicationId: item.providerCommunicationId,recallSiteCount: item.recallSiteCount},
                })
            }
            else
            {
                this.$router.push({
                    name: 'prov_DisruptionView',
                    params: { id: item.communicationId ,providerCommunicationId: item.providerCommunicationId,recallSiteCount: item.recallSiteCount},
                })
            }
        },
        viewRecall(item) {
            var Childrecall= this.Originalitems.filter(s=>s.communicationId==item.communicationId && s.recallSiteId>0 && s.recallSiteCount>1)
            if(Childrecall.length>0)
            {
                this.ChildRecall=Childrecall
                this.ChildRecalltotal= Childrecall.length
                this.title=item.communicationType == this.recall ? 'Recalls' : 'Supply Advisories'
                this.$root.$emit('bv::show::modal', 'modal-xl')
            }
            else{
                if(this._checkPermissions(['Provider_ManageRecalls','Provider_ManageDisruption'])) {
                    if(item.communicationType == 'RECALL')
                    {
                        this.$router.push({ name: 'prov_RecallView', params: { id: item.communicationId ,providerCommunicationId: item.providerCommunicationId,recallSiteCount: item.recallSiteCount} })
                    }
                    else
                    {
                        this.$router.push({ name: 'prov_DisruptionView', params: { id: item.communicationId ,providerCommunicationId: item.providerCommunicationId,recallSiteCount: item.recallSiteCount} })

                    }
                }
            }
        },
        calcHeight() {
            let maxHeight = (type) => {
                var maxheight = 0
                for (const prop in this.$refs) {
                    for (let i = 0; i < this.items.length; i++) {
                        if (prop == type+i) {
                            var height = this.$refs[prop][0].clientHeight
                            maxheight = maxheight <= height ? height : maxheight
                        }
                    }
                }
                return maxheight
            }

            this.minHeight = {
                meta: maxHeight('meta'),
                title: maxHeight('title'),
                cate: maxHeight('cate')
            }
        },
        badgeMsgClick(item) {
            if (item.first_conversation_id && item.first_conversation_id > 0 && this._checkPermissions(['Provider_ManageRecalls'])) {
                this.$router.push({ name: 'prov_RecallView', params: { id: item.communicationId ,providerCommunicationId: item.providerCommunicationId}, query: {conId: item.first_conversation_id} })
            }
        }
    }
}